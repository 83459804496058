<template>
  <div class="auto_css">
    <div class="z_img">
      <el-image class="imgw145 imgbg" :src="require('@/assets/ztop.png')" />
    </div>
    <div class="auto_bg">
      <div class="w1400">
        <div class="contetn">
          <iframe class="iframe" :src="$t(`Terms.src`)" id="qt" frameborder="0">
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  name: `Terms`,
  setup() {
    const zauto = require('@/assets/zauto.png')
    const zautoen = require('@/assets/en/zauto.png')
    const autoimgyn = sessionStorage.getItem('lang')
    return { zautoen, zauto, autoimgyn }
  },
}
</script>
    
    <style scoped>
.contetn {
  padding-top: 100px;
}
.iframe {
  width: 100%;
  background: #fff;
  height: 500px;
}
</style>
    