<template>
  <div class="footer">
    <div class="h90"></div>
    <p>
      <!-- <el-button type="text" class="ys" @click="btnindex">{{
        $t(`Nav.Home`)
      }}</el-button>
      |
      <el-button type="text" class="ys" @click="btnauto">{{
        $t(`Nav.About`)
      }}</el-button>
      | -->
      <!-- <el-button type="text" class="ys" @click="btnjoin">{{
        $t(`Nav.Joinus`)
      }}</el-button>
      | -->
      <!-- <el-button type="text" class="ys" @click="btncontactUs">{{
        $t(`Nav.contactUs`)
      }}</el-button
      >| -->
      <a type="text" class="ys" :href="$t(`Secret.src`)" target="blank">{{
        $t(`Nav.secret`)
      }}</a
      >|
      <a type="text" class="ys" :href="$t(`Terms.src`)" target="blank">{{
        $t(`Nav.terms`)
      }}</a>
      <!-- |<el-button type="text" class="ys" @click="btnterms">{{
        $t(`Nav.terms`)
      }}</el-button> -->
    </p>
    <p>
      {{ $t(`Footer.cont`) }}
    </p>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  name: `footer`,
  setup() {
    const router = useRouter()
    // const lang = sessionStorage.getItem('lang')
    function btnindex() {
      router.push({ path: '/' })
    }
    function btnauto() {
      router.push({ path: '/auto' })
    }
    function btncontactUs() {
      router.push({ path: '/ContactUs' })
    }
    function btnsecert() {
      router.push({ path: '/policy' })
    }
    function btnterms() {
      router.push({ path: '/terms' })
    }
    function btnjoin() {
      router.push({ path: '/ContactUs' })
    }
    return { btnindex, btnauto, btncontactUs, btnsecert, btnterms, btnjoin }
  },
}
</script>

<style scoped>
.footer {
  background: url('../../src/assets/footerbg.png') no-repeat bottom;
  height: 274px;
  margin-top: -40px;
}
.h90 {
  height: 90px;
}
.footer p {
  color: #ffffffb3;
  margin: 10px 0;
}
.ys {
  color: #ffffffb3;
  margin: 0 12px;
  font-size: 14px;
}
</style>
