<template>
  <div class="auto_css">
    <div class="z_img">
      <el-image class="imgw145 imgbg" :src="require('@/assets/ztop.png')" />
    </div>
    <div class="us_bg">
      <div class="w1400">
        <div class="title">
          <el-image
            class="imgw145"
            :src="autoimgyn == 'en' ? ustitleen : ustitle"
          />
        </div>
        <div class="contetn">
          <div class="contus pr">
            <div class="w1400">
              <div class="pa dz">
                <b>{{ $t(`contactus.title`) }}</b>
                <samp>{{ $t(`contactus.address`) }}</samp>
              </div>
              <div class="pa wte">
                <ul>
                  <!-- <li>
                    <span>
                      <el-image
                        class="imgw"
                        :src="require('@/assets/tt.png')"
                      />
                    </span>
                    <p>
                      <b>Twitter</b>
                      <samp>@xinshengfun</samp>
                    </p>
                  </li> -->
                  <li>
                    <span>
                      <el-image
                        class="imgw"
                        :src="require('@/assets/wx.png')"
                      />
                    </span>
                    <p>
                      <b>WhatsApp</b>
                      <samp>+852 46618373</samp>
                    </p>
                  </li>
                  <li>
                    <span>
                      <el-image
                        class="imgw"
                        :src="require('@/assets/emil.png')"
                      />
                    </span>
                    <p>
                      <b>Email</b>
                      <samp>huanyutop@gmail.com</samp>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `ContactUs`,
  setup() {
    const ustitle = require('@/assets/ustitle.png')
    const ustitleen = require('@/assets/en/ustitle.png')
    const autoimgyn = sessionStorage.getItem('lang')
    return { ustitleen, ustitle, autoimgyn }
  },
}
</script>

<style scoped>
</style>
