<template>
  <div class="affix-container">
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item class="logobtn">
        <el-image
          class="imgw145"
          style="width: 230px"
          :src="require('@/assets/logos.png')"
        />
      </el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="1">{{ $t(`Nav.Home`) }}</el-menu-item>
      <!-- <el-menu-item index="2">{{ $t(`Nav.About`) }}</el-menu-item> -->
      <!-- <el-menu-item index="3">{{ $t(`Nav.Game`) }} </el-menu-item> -->
      <!-- <el-menu-item index="4">{{ $t(`Nav.Joinus`) }}</el-menu-item> -->
      <!-- <el-menu-item index="5">{{ $t(`Nav.contactUs`) }}</el-menu-item> -->
      <el-menu-item index="6">{{ $t(`Nav.safe`) }}</el-menu-item>
      <el-select
        v-model="languagevalue"
        class="m-1"
        placeholder="Select"
        size="small"
        @change="change"
      >
        <el-option
          v-for="item in language"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-menu>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n' //要在js中使用国际化
export default {
  name: `Nav`,
  setup() {
    const activeIndex = ref('')
    const languagevalue = ref('')
    const router = useRouter()
    const { proxy } = getCurrentInstance()
    const language = [
      {
        value: 'zh_cn',
        label: '中文',
      },
      {
        value: 'en',
        label: 'English',
      },
    ]
    if (sessionStorage.getItem('active') != undefined) {
      activeIndex.value = sessionStorage.getItem('active')
    } else {
      activeIndex.value = '1'
    }
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath)
      console.log('key', keyPath)
      sessionStorage.setItem('active', key)
      if (key == '1') {
        router.push({ path: '/' })
      } else if (key == '2') {
        router.push({ path: '/auto' })
      } else if (key == '5') {
        router.push({ path: '/ContactUs' })
      } else if (key == '3') {
        router.push({ path: '/Gamelist' })
      } else if (key == '6') {
        router.push({ path: '/Account' })
      }
    }
    const { t, locale } = useI18n()
    if (sessionStorage.getItem('lang') != undefined) {
      languagevalue.value = sessionStorage.getItem('lang')
      locale.value = sessionStorage.getItem('lang')
    } else {
      languagevalue.value = 'en'
      locale.value = 'en'
      sessionStorage.setItem('lang', 'en')
    }
    function change(type) {
      sessionStorage.setItem('lang', type)
      sessionStorage.setItem(
        'gameData',
        JSON.stringify(proxy.$store.state.gameData)
      )
      location.reload()
    }

    return { activeIndex, handleSelect, change, t, languagevalue, language }
  },
}
</script>

<style scoped>
.affix-container {
  background: url('../../src/assets/navbg.png');
  height: 85px;
}
.el-menu {
  width: 1400px;
  margin: 0 auto;
  background-color: initial;
  border-bottom: none;
  height: 85px;
}
.flex-grow {
  flex-grow: 1;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  color: #ffbc00 !important;
}
.el-menu--horizontal > .el-menu-item {
  color: #ffffff;
  font-size: 20px;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  color: #ffffff !important;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #ffbc00;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: inherit;
  color: #ffbc00;
  border-bottom: 2px solid #ffbc00;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: inherit;
  color: #ffbc00;
  border-bottom: 2px solid #ffbc00;
}
.m-1 {
  margin-top: 30px;
  width: 140px;
}
.el-input__wrapper {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  box-shadow: none;
}
</style>
