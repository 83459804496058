<template>
  <div class="auto_css">
    <div class="z_img">
      <el-image class="imgw145 imgbg" :src="require('@/assets/ztop.png')" />
    </div>
    <div class="us_bg">
      <div class="w1400">
        <div class="title">
          <el-image
            class="imgw145"
            :src="autoimgyn == 'en' ? gametitleen : gametitle"
          />
        </div>
        <div class="contetn">
          <div class="gamecont pr">
            <div class="w1200">
              <ul>
                <li @click="goDetail('ailin')">
                  <div class="pr">
                    <el-image
                      class="imgw145"
                      :src="require('@/assets/ailin/1.jpg')"
                    />
                  </div>
                </li>
                <li>
                  <div class="pr">
                    <el-image
                      class="imgw145"
                      :src="require('@/assets/blank.jpg')"
                    />
                    <span class="pa">{{ $t(`commencont.Coming`) }}</span>
                  </div>
                </li>
                <li>
                  <div class="pr">
                    <el-image
                      class="imgw145"
                      :src="require('@/assets/blank.jpg')"
                    />
                    <span class="pa">{{ $t(`commencont.Coming`) }}</span>
                  </div>
                </li>
                <li>
                  <div class="pr">
                    <el-image
                      class="imgw145"
                      :src="require('@/assets/blank.jpg')"
                    />
                    <span class="pa">{{ $t(`commencont.Coming`) }}</span>
                  </div>
                </li>
                <li>
                  <div class="pr">
                    <el-image
                      class="imgw145"
                      :src="require('@/assets/blank.jpg')"
                    />
                    <span class="pa">{{ $t(`commencont.Coming`) }}</span>
                  </div>
                </li>
                <li>
                  <div class="pr">
                    <el-image
                      class="imgw145"
                      :src="require('@/assets/blank.jpg')"
                    />
                    <span class="pa">{{ $t(`commencont.Coming`) }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: `ContactUs`,
  setup() {
    const gametitle = require('@/assets/gametitle.png')
    const gametitleen = require('@/assets/en/gametitle.png')
    const autoimgyn = sessionStorage.getItem('lang')
    const router = useRouter()

    const goDetail = (type) => {
      if (type.length < 1) {
        return
      }
      router.push(`/Gameinside?id=${type}`)
    }
    return { gametitleen, gametitle, autoimgyn, goDetail }
  },
}
</script>

<style scoped>
</style>
